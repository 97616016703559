import * as React from 'react';
 import logo from './images/ct.png';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import styles from './style.module.css';

  
const data = [
    { label: 'Home', path: './' },
    { label: 'About', path: 'about' },
    { label: 'STAFFING SERVICS', path: 'staffing-services', head:'700' },
    { label: 'Permanent Staffing & Talent Acquisition  ', path: 'permanent-staffing' },
    { label: 'Contract Staffing & Contingent Workforce ', path: 'contract-staffing' },
    { label: 'Technical Skills Assessment', path: 'technical-screening' },
    { label: 'TRAINING-DEVELOPMENT', path: 'training-development' },
    { label: 'Campus to Corporate', path: 'campus-to-corporate' },
    { label: 'Advanced Technical program', path: 'advanced-technical-program' },
    { label: 'Train & Deploy', path: 'train-and-deploy' },
    { label: 'Soft Skills and Leadership Program', path: 'soft-skills-leadership-program' },
    { label: 'CLOUD SOLUTIONS', path: 'cloud-solutions' },
    { label: 'Cloud Migration ', path: 'cloud-migration' },
    { label: 'Cloud Maintenance  ', path: 'cloud-maintenance' },
    { label: 'Security Solutions', path: 'security-solutions' },
    { label: 'DevOps', path: 'devops' },
    { label: 'AUTOMATION & ANALYTICS', path: 'automation-analytics' },
    { label: 'Big Data', path: 'big-data' },
    { label: 'IoT -Internet of Things', path: 'iot' },
    { label: 'RPA - Robotics Process Automation', path: 'rpa' },
    { label: 'Data Analytics', path: 'data-analytics' },
    { label: 'SOFTWARE DEVELOPMENT ', path: 'software-development' },
    { label: 'Enterprise Application Development', path: 'enterprise-application-development' },
    { label: 'Mobile & Web Development ', path: 'mobile-and-web-development' },
    { label: 'UI/UX Design ', path: 'uiux' },
    { label: 'Embedded Solutions ', path: 'embedded-solutions' },
    { label: 'Quality Assurance ', path: 'quality-assurance' },
    { label: 'SUPPORT', path: 'support' },
    { label: 'Application Support ', path: 'application-support' },
    { label: 'Infrastructure Support ', path: 'infrastructure-support' },
    { label: 'IT-helpdesk', path: 'it-helpdesk' },
    { label: 'Contact us', path: 'contact' },


];


export default function SwipeableTemporaryDrawer() {

   



    const [state, setState] = React.useState({
              right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

  
    const list = (anchor) => (
        <Box
            sx={{ width:300, fontSize:13, padding:2, textDecoration:null,}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
         
             
            {
                data.map((item) => (
                          
            <Link href={item.path} style={{ textDecoration: 'none'  , color:'#333', fontWeight:'700'}}   >     
                        <strong><ListItemText primary={item.label} /> </strong>
                    </Link>
                    
                ))}
                
            

        </Box>
    );

    return (
        <div>
                    
                    <SwipeableDrawer anchor="right" open={state['right']} onClose={toggleDrawer('right', false)} onOpen={toggleDrawer('right', true)} >
                        {list('right')}
                    </SwipeableDrawer>
            <Container maxWidth="lg" className={styles.container}  >
                <AppBar maxWidth="lg" sx={{ background:'#6b2463'}}>
                <Toolbar  >
                 <Link href="./">
                    <img src={logo} width="290" sx={{marginTop:2,}}  alt=""/>
                        </Link>
                    <IconButton
                        size="large"
                        edge="start"
                         aria-label="menu"
                        sx={{ mr: 2, color:'#666' }}
                    >
                        
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                     </Typography>
                        <MenuIcon size="large"  onClick={toggleDrawer('right', true)} /> 
                </Toolbar>
            </AppBar>
            </Container>
         </div>


    );
}

