import Home from './home';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Footer from './footer';
import Header from './header';
import Contact from './contact';
import About from './about';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Typography } from '@mui/material';
import { Item } from './main';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import hero from './images/hero.svg';
import { Gridshome } from './main';
 import Divider from '@mui/material/Divider';
import styles from './style.module.css';
 
 



export default function Routing() {
  return (
    <div className={styles.container}  >


      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="people" element={<People />} />
          <Route path="technology" element={<Technology />} />
          <Route path="digital-innovation" element={<Digitalinnovation />} />
          <Route path="staffing-services" element={<StaffingServices />} />
          <Route path="contract-staffing" element={<Contractstaffing />} />
          <Route path="permanent-staffing" element={<PermanentStaffing />} />
          <Route path="training-development" element={<TrainingDevelopmentServices />} />
          <Route path="campus-to-corporate" element={<CampusCorporate />} />
          <Route path="advanced-technical-program" element={<AdvancedTechnicalProgram />} />
          <Route path="train-and-deploy" element={<TrainDeploy />} />
          <Route path="soft-skills-leadership-program" element={<SoftskillsLeadership />} />
          <Route path="security-solutions" element={<SecuritySolutions />} />
          <Route path="cloud-maintenance" element={<CloudMaintenance />} />
          <Route path="cloud-migration" element={<CloudMigrations />} />
          <Route path="devops" element={<DevOps />} />
          <Route path="automation-analytics" element={<AutomationAnalytics />} />
          <Route path="big-data" element={<BigData />} />
          <Route path="iot" element={<IoT />} />
          <Route path="rpa" element={<RPA />} />
          <Route path="data-analytics" element={<DataAnalytics />} />
          <Route path="software-development" element={<SoftwareDevelopment />} />
          <Route path="enterprise-application-development" element={<EnterpriseApplication />} />
          <Route path="mobile-and-web-development" element={<MobileWebDevelopment />} />
          <Route path="uiux" element={<UIUX />} />
          <Route path="embedded-solutions" element={<EmbeddedSolutions />} />
          <Route path="quality-assurance" element={<QualityAssurance />} />
          <Route path="support" element={<Support />} />
          <Route path="application-support" element={<ApplicationSupport />} />
          <Route path="it-helpdesk" element={<ITSupports />} />
          <Route path="contact" element={<Contact />} />

 

          
        </Routes>
      </BrowserRouter>

    </div>
  );
}





function People() {
  return (
    <div  >
      <Header />
      <Box className={styles.breadcrumb}>
        <Typography variant="h3" >
          People and Consulting
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    ACCESS SKILLS                          </Typography>
                  <Typography variant="p" component="p"   >


                    Agile workforce development solutions that attract, develop and engage skills

                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    EMPLOYEE EXPERIENCE                          </Typography>
                  <Typography variant="p" component="p"   >


                    Exceptional experiences that help build and maintain an inclusive and engaged workforce .

                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    FINANCIAL STABILITY
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Incentive strategies that help your organisation and people attain a secure financial future.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  A TALENTED WORKFORCE TO SUPPORT YOUR SUCCESS
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  We offer our digital services throughout the recruitment life cycle to help you with valuable insights into what you should expect from your workforce to succeed and grow. By helping you understand the most valuable asset -your workforce -we keep you stay connected with them and makes better hiring decisions for your organisation.                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function Technology() {
  return (
    <div  >

      <Header />
      <Box className={styles.breadcrumb}>
        <Typography variant="h4"  >
          Technology
        </Typography>
      </Box>
      <Container maxWidth="lg" className={styles.container}  >

        <Grid maxWidth="lg" container className={styles.gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={250} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    ACCELERATED DEVELOPMENT                           </Typography>
                  <Typography variant="p" component="p"   >


                    Our development platforms accelerate delivery and cut down the cost.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={250} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    TOTAL TRANSPARENCY                          </Typography>
                  <Typography variant="p" component="p"   >


                    We keep our clients updated about the project and give them a clear picture of the status.

                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={250} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    REVOLUTIONARY TESTING
                  </Typography>
                  <Typography variant="p" component="p"   >
                    We help you improve communication between testers and developers through our technology.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container className={styles.gridshome} >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  WE HELP YOU BUILD YOUR FUTURE
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Our professional developers work round the clock to improve our clientsâ€™ businesses by appropriately using digital technology. <br />Having been assisting companies across industries for years, we have developed our digital systems to help our clients become more competitive and adaptable in a dynamic environment. We aim to be your bespoke technology partner who will walk with every step of the digital transformation journey.

                  <br />
                  Leveraging the latest analytics tools, and robust technologies, we provide secure and scalable solutions that align with your business needs and help you make better data-driven decisions. <br />Our superior expertise allows us to develop a strategy that integrates Big Data for clients with different business goals and hails from a wide array of industries.                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  We believe in building relationships with our clients on trust. Our collaborative approach helps us deliver the best results always.<br /> We utilize our in-house tools to work better with our customers, provide practical solutions, and provide a more positive overall experience.

                  <br />
                  Leveraging the latest analytics tools, and robust technologies, we provide secure and scalable solutions that align with your business needs and help you make better data-driven decisions. Our superior expertise allows us to develop a strategy that integrates Big Data for clients with different business goals and hails from a wide array of industries.                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>
      </Container>
      <Footer />

    </div>
  );
}

function Digitalinnovation() {
  return (
    <div className={styles.container}  >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Digital Innovation
        </Typography>
      </Box>
      <Container maxWidth="lg" disableGutters >


        <Grid maxWidth="lg" container className={styles.gridshome} >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  WE HELP YOU BUILD YOUR FUTURE
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Corner Tree takes pride in our outstanding track record of success in accelerating business growth through our innovative services focused on technology. We are passionate about sustainability. Hence, leveraging our experience and reliable tools, we take a unique approach to help contextualise your challenges.

                  <br />
                  <br />

                  At Corner Tree consulting, we continue to thrive by helping business across industries get an edge over their competitors. Being experts in the innovation process, we draw from a wide array of skills and expertise to cut across sector boundaries and provide business with the knowledge and solutions required to accelerate their customer journey.
                  <br />
                  <br />


                  INNOVATION. ACCELERATION. IMPACT.
                  <br />
                  At Corner Tree, we integrate technology scouting and systematic innovation to address challenges efficiently. We drive economic prosperity across the country by helping businesses grow.                 </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >

                  At Corner Tree, we fully appreciate and embrace the value of building new technology and products. In fact, our innovative design approach, a vast network of industry partners, and robust engineering culture are what helps us efficiently transform emerging technologies into commercial products and proof of concept prototypes for you.
                  <br />


                  <br />
                  <br />

                  We have put together a thorough development process to identify potential challenges and develop the technology to a completely functioning prototype. Not only do we take a truly innovative approach, but we also make sure that it is inventive.

                  <br />
                  <br />


                  <strong> INNOVATION TO BOOST GROWTH & PROFITABILITY </strong>

                  <br />


                  At Corner Tree, a product is born from random collisions of half-thoughts followed by a 'what if' conversation. Throw in some excitement, and a collaborative effort to turn the idea into a product that can be marketed and there you have our fantastic product development process.
                  <br />
                  <br />



                  We boost your team ability by consistently providing fresh and useful innovation and ensuring that the product you plan to market is worth a full scale of investment in development. Reach out to us today, and we will help you identify the right market for your product even before the technology development.
                  <br />



                  We change the way you listen and respond to customers, work with each other and implement changes.
                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>
      </Container>
      <Footer />

    </div>
  );
}

function StaffingServices() {
  return (
    <div   >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Staffing Services
        </Typography>
      </Box>
      
      <Container maxWidth="lg" className={styles.container}  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    EXTENSIVE NETWORK
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Leveraging our vast pool of talented professionals who are ready to work, we help your hire trained professions who are perfectly suitable for your company.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    COST REDUCTION                       </Typography>
                  <Typography variant="p" component="p"   >


                    We ensure that organizations don't have to pay an extra amount in processes like pre-employment testing, recruitment, background check, and payroll processing, and management.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    RETENTION
                  </Typography>
                  <Typography variant="p" component="p"   >
                    We help temporary employees hired through us learn and improve before they are offered a permanent position.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Experts In The Recruitment Industry
                  VERSATILE STAFFING SOLUTIONS                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Efficiency, transparency, and attention to detail are some of the most valued attributes at Corner Tree. These critical attributes alongside our market knowledge make us the most customer-relevant staffing company in the country.
                  It also makes us the recruiter of choice for many of our regular clients.   </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function Contractstaffing() {
  return (
    <div className={styles.container}  >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Contract Staffing Services
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    RETENTION
                  </Typography>
                  <Typography variant="p" component="p"   >


                    We help the candidates learn and improve throughout their contract term to help them evolve to become suitable for permanent positions.

                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    ENHANCED ACCURACY                       </Typography>
                  <Typography variant="p" component="p"   >


                    We take care of every small detail of payroll operations to help your experience more accuracy of operations.

                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    RISK MANAGEMENT
                  </Typography>
                  <Typography variant="p" component="p"   >
                    We help you abide by all laws and regulations, thus minimising the compliance risks..
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Experts In The Recruitment Industry
                  Reach Out To Us At Corner Tree
                  FINDING RELIABLE TALENTS FOR YOUR COMPANY                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Being one of the leading contract staffing agencies in the country, we blur the gap between your organization's current and future goals through efficient resources.

                  Leveraging our proven recruitment process and agile methodology, we help companies speed up their hiring process and save both time and money.   </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function PermanentStaffing() {
  return (
    <div className={styles.container}  >

      <Header />

      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Permanent Staffing
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    FASTER HIRING
                  </Typography>
                  <Typography variant="p" component="p"   >


                    We can find candidates much faster than you can by leveraging our talent pool and network connection, thus reducing the hiring time.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    HIGH-QUALITY CANDIDATES                       </Typography>
                  <Typography variant="p" component="p"   >


                    We help you connect with high-quality candidates from our talent pool of pre-screened and referenced professionals.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    KNOWLEDGE OF THE MARKET
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Having worked with a wide array of organizations, we provide you with valuable insight and sage advice.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Experts In The Recruitment Industry
                  Hire For Job Roles Worldwide
                  HOW CAN WE HELP YOU        </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  We help both job seekers and recruiters at every stage of the hiring process. Whether you are searching for permanent or contract positions, Corner Tree can help you find the right talents for your business goals.  </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function TrainingDevelopmentServices() {
  return (
    <div className={styles.container}  >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Training Development Services
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    CUSTOMISED SOLUTIONS
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Learning experiences tailored to your business culture and brand to help you welcome new staff retains talents, and keep them motivated and informed.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    OFFERING A DIGITAL BACKBONE                       </Typography>
                  <Typography variant="p" component="p"   >


                    We blend digital learning with your existing learning and training programmes to create a â€˜digital backboneâ€™ that makes learning more efficient..
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    THE RIGHT STRATEGIES
                  </Typography>
                  <Typography variant="p" component="p"   >
                    We select the right strategies and game mechanics to motivate and immerse people in topics they might find a struggle to understand.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Meet Your Business Evolving Demands
                  SKILL DEVELOPMENT THAT SUPPORTS YOUR BUSINESS     </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Even the best employees should continuously develop their skills to meet your evolving business needs. Hence, continuous learning is a crucial ingredient for success and a way to make your company future-proof.

                  We help you implement skill-based learning and development strategies that ensure continuous learning and training in your business. Through this method, we help you level-up and attract new talents and empower your current employees to boost your business. However, we understand that each company has its own unique journey. Our training and development programs are designed after working closely with your team to ensure that it complements your company's values.

                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function CampusCorporate() {
  return (
    <div className={styles.container}  >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Campus Corporate
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    A BENCHMARK STRATEGY
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Taking the whole picture into account to develop a benchmark strategy that ensures you acquire the right skills.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    RELEVANCY                      </Typography>
                  <Typography variant="p" component="p"   >


                    Through our services, we ensure that we create conditions that keep people engaged in achieving quality objectives
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    POST-TRAINING REINFORCEMENT
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Applying new concepts and post-training to help employees apply what they have learned in their everyday life.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  For Unique Needs
                  Campus to Corporate Training Programs for you    </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  We believe that each student possesses different potentials. Hence we design our programmes in a way that allows each candidate to uplift their personal skills, soft skills, aptitude skills, and technical skills.

                  Our expert guidance, paired with the right tools, helps employers' find students whose presence is in the Green zone of success.



                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function AdvancedTechnicalProgram() {
  return (
    <div className={styles.container}  >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Advanced Technical Program
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    Freshers on boarding company
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Freshers on boarding company
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    Re skill,Up skill,Right skill                    </Typography>
                  <Typography variant="p" component="p"   >


                    Re skill,Up skill,Right skill
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    Customised program across levels/skills
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Customised program across levels/skills
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Prioritization</Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Prioritization
                  Our expert guidance, paired with the right tools, helps employers' find students whose presence is in the Green zone of success.



                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function TrainDeploy() {
  return (
    <div className={styles.container}  >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Train Deploymnet
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    We Train
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Providing top-notch training to impart relevant technical skills and commercial experience to the individuals
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    We Deploy                  </Typography>
                  <Typography variant="p" component="p"   >


                    Offering professional and technical support and scalable resources to underpin the training
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    We Build
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Building your talent pipeline and enabling our clients to retain knowledge and skills while hiring experienced professionals to their talent pool.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Hire, Train and Deploy
                  WELCOME TO CORNER TREE</Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  We offer exclusive, result-driven, and process-driven models that deliver high-performing, trained, and ready-to-hire professionals to your business.

                  Businesses worldwide are in the hunt for capable trainees, graduates, and professionals to start working with them immediately but without investing a lot of resources on training them. In such a scenario, we help our clients find talented employees through a training program we have developed that benefits both parties.

                  At Corner Tree, our Campus to Corporate training program is essentially a soft skills program that helps candidates seamlessly enter corporate life with confidence. The session provides tips and tools for various challenges like time management, corporate communication, goal setting, teamwork, stress management, and more.

                  Our offerings include a well-established selection program that unites fully trained employees with the right company that aligns with their skills and talents.

                  We open the door to the corporate world for skilled and talented candidates


                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function SoftskillsLeadership() {
  return (
    <div className={styles.container}  >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Soft skills Leadership
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    Managing Better
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Managing Better
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    Change Management                  </Typography>
                  <Typography variant="p" component="p"   >


                    Change Management
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    Productivity Management
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Productivity Management
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Performance Management</Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Performance Management

                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function SecuritySolutions() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Security Solutions

        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    IMPROVING SECURITY POSTURE
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Shining light on the risks and improving your current on-premise service to improve security posture and help you go beyond the cloud.              </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    ALIGNING CLOUD AND INFORMATION SECURITY

                  </Typography>
                  <Typography variant="p" component="p"   >


                    Assisting you in aligning cloud security with your information security program, leveraging the best tools.

                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    SECURITY BEST PRACTICES
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Mitigating known attacks and vulnerabilities by appropriately using A.A.A. and encryption key management principles.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Our Focus
                  DINNOVATIVE SECURITY SOLUTIONS FOR THE CLOUD</Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  At Corner Tree, we bring fresh approaches to smart cloud security on to the table. Our agile and integrated methodology, many years of expertise, and product and service offerings allow us to deliver advanced solutions that give you the real edge in the ever-competitive marketplace.



                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function CloudMaintenance() {
  return (
    <div className={styles.container}  >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Cloud Maintenance
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    SERVER MONITORING
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Using top-notch software to monitor your server 24/7 so that our engineers can get into actions whenever thereâ€™s an issue                 </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    SERVER UPDATE

                  </Typography>
                  <Typography variant="p" component="p"   >


                    Keeping your system up-to-date and protecting your application and server from threats and attacks.

                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    SERVER BACKUP
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Putting a disaster recovery plan into place and taking point-in-time backups of server, applications, and database.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Cloud Maintenance Services
                  CLOUD MAINTENANCE FOR YOUR CHANGING BUSINESS</Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  It's essential that cloud solutions be adequately maintained to minimise downtime. However, keeping up with maintenance contracts for multi-vendor devices alongside the cloud can be a headache and, above all, time-consuming.

                  At Corner Tree, we act as a single point of contact for all your IT maintenance needs, including the cloud. We proactively keep your cloud up-to-date and resolve issues quickly. Our services come in a wide range of support levels and take the pressure away from you.

                  Our India-based technical service centre provides 24x7 assistance for maintenance, and whenever there's an issue, we will have your cloud back up and running quickly with minimal disruption to your business.
                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function CloudMigrations() {
  return (
    <div className={styles.container}  >

      <Header />
      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Cloud Migrations
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    CLOUD MIGRATION
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Managing the migration of your system between clouds, so you don't have to worry about choosing or setting up a new infrastructure.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    Cloud-to-cloud movement

                  </Typography>
                  <Typography variant="p" component="p"   >


                    Helping you configure your cloud set up with your existing provider to let you enjoy a better solution. </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    CLOUD SERVICE MIGRATION
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Helping you find your requirements and decide on migrating from one service provider to another.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Our Expertise
                  ACCELERATE YOUR PUBLIC CLOUD MIGRATIONS</Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  With years of expertise in cloud migration, we possess the right business tools and practice the proper techniques to avoid the typical hassles when making the move.

                  Our cloud migration services offer the highest returns and deliver enterprise solutions that are secure and reliable.
                  The safe and secure infrastructure provides back-office support and preserves capital to enable IT staff to work on innovation and growth.

                  It further improves alignment between IT and the business by offering an agile IT infrastructure that's scalable and available on demand.
                  Enterprise cloud benefits provide real and significant value by allowing your business to migrate its applications and workloads into the cloud platform without costing time and risk that's typically involved in the process.

                  It means you can accelerate the delivery of cloud benefits for your organisation by making the migration process painless, fast, and straightforward.

                  At Corner Tree, we help organisations like yours implement an effective RPA program and assist with user adoption to ensure success.

                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function DevOps() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          DevOps
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    Code Inspection & Integration
                  </Typography>
                  <Typography variant="p" component="p"   >


                    We scan your system thoroughly to get to the bottom of any issues and fix them at the earliest.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    End to End Automation                      </Typography>
                  <Typography variant="p" component="p"   >


                    Streamlining day-to-day user management, access, and security protocols and performing database services.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    DevOps pipeline
                  </Typography>
                  <Typography variant="p" component="p"   >
                    DevOps represents a set of practices that incorporates software development (Dev) and IT operations (Ops).
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  What is DevOps
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  DevOps lacks a fixed definition as itâ€™s not a workflow or framework. Rather, DevOps is a shift of culture to make businesses more adaptive to changes.
                  DevOps is essentially about bringing together the development and the operations teams in a software development firm.

                  Both teams collaborate to eliminate silos and strive for quicker innovation adoption. Under a DevOps culture, your dev team writes codes with the production environment in mind. As a result, the whole team is responsible for performance and efficiency.

                  DevOps helps dev and operations work together, deploy apps faster, make changes without disruption, and recover faster. You can create a product resilient to the risks that haunt software companies.
                  Now, letâ€™s see how exactly DevOps is responsible for business growth.</Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function AutomationAnalytics() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Automation Analytics
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    CUSTOMER ANALYTICS
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Connect with your audience and personalise customer experiences with analytics capabilities
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    INTERNET OF THINGS                      </Typography>
                  <Typography variant="p" component="p"   >


                    Leverage the power of IoT, find new business opportunities, and take your business to the next level
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    DATA ESTATE MANAGEMENT
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Tap into high-quality data and lay the foundation for AI and other emerging technologies.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Systematic Approach
                  AUTOMATION SOLUTIONS FOR YOUR ORGANIZATIONS
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  At Corner Tree, we help you turn your processes and make them highly scalable, automated, and intelligent. Our services aim to help you maintain a highly reliable technology that will act as the backbone of your business. We follow a systematic approach that leaves no room for gaps in requirements.
                  Through a series of interactive sessions, we understand our clients' business areas that can be considered for automation.
                  Depending on the process depth, technology used, data structure, and the application characteristics, we proceed to derive the complexity levels for process assessment. We always work to identify the processes that demand automation and then work to help you make the most out of these processes.

                  Our close-knitted team comes up with a strategy for envisaging specific goals like IT infrastructure, security, governance, and change to offer our clients the best support.

                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function BigData() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Big Data
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    PLATFORM SUPPORT
                  </Typography>
                  <Typography variant="p" component="p"   >


                    The right tools to analyse and use valuable insights to solve business problems and gain competitive advantage.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    DATA ENGINEERING                     </Typography>
                  <Typography variant="p" component="p"   >


                    Optimise your data warehouse to align with your business goals through data migration and consolidation for improved business agility and adaptability.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    ANALYTICS
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Reporting and analysis techniques designed to accelerate business growth and achieving both long-term and short-term goals
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Fast Data Processing
                  WE ARE YOUR BEST CHOICE TO WORK WITH
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Regardless of the industry and size of the business, vast amounts of big data are created over the course of every business's existence.

                  This normal process brings the necessity of mining, storage, and integration in real-time.
                  At Corner Tree, we offer expertise-grade engineering solutions to identify, secure, and govern your big data with the right management and visualization tools.


                  Leveraging the latest analytics tools, and robust technologies, we provide secure and scalable solutions that align with your business needs and help you make better data-driven decisions.

                  Our superior expertise allows us to develop a strategy that integrates Big Data for clients with different business goals and hails from a wide array of industries.

                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function IoT() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          IoT Journey
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    ENGAGE & ENABLE
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Evaluating your current offering and leveraging the insights to architect your IoT solutions for successful transformation.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    BUILD & DEPLOY                    </Typography>
                  <Typography variant="p" component="p"   >


                    Selecting the right technology platform solutions and building and integrating applications and IoT platforms.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    MANAGE, ANALYSE & CORRELATE
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Collecting and transforming data into customer, product, and service insights to create intelligent systems and optimise performance.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Digital Transformation
                  BE INTELLIGENT WITH IOT
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Leveraging IoT, we deliver innovations that push growth across the value chain. The right combination of data, people, and intelligent machines improves productivity, efficiency, and operations across industries.

                  We open up a new era of economic growth and competitiveness with our IoT offering that catalyse digital transformation.

                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function RPA() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          RPA - Robotics Process Automation
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    EPROCESS EVALUATION
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Evaluating and identifying processes suitable for automation, support, and standardization of the identified processes.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    PROOF OF CONCEPT                    </Typography>
                  <Typography variant="p" component="p"   >


                    Performing pilot execution of RPA offerings and developing business cases necessary to obtain stakeholder buy-in and support
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    TRAINING AND SUPPORT
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Developing and delivering training to support end-users and administrations and address the operational and organisation impacts of automation.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Automating Process Across Platforms
                  BE PART OF THE INTELLIGENT AUTOMATION ERA
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  With business tools and applications growing at a fast rate, it becomes difficult to interpret the different data streams. While an individual digital system isn't hard to manage, most organizations struggle with confronting the issue of managing multiple systems towards a unified business goal.
                  We help organisations tackle this hurdle leveraging the power of robotic process automation (RPA).

                  Before we embark on your project, we spend time understanding your existing business processes and their sustainability for automation.

                  Additionally, alongside your short-term goals, we also take into account your long-term goals to find the best RPA solution or platform for your needs.
                  At Corner Tree, we help organisations like yours implement an effective RPA program and assist with user adoption to ensure success.


                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function DataAnalytics() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Data Analytics
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    DATA ANALYSIS & MINING
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Helping clients identify inconsistencies within the organisation and overcome it with our analytics tools and models.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    DATA VISUALISATION                    </Typography>
                  <Typography variant="p" component="p"   >


                    Performing pilot execution of RPA offerings and developing business cases necessary to obtain stakeholder buy-in and support.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    RISK ANALYTICS
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Using our data capabilities as a part of the internal audit assessments to quantify and identify patterns and trends for management.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Be Part Of The Digital Era
                  BECOME A DATA-DRIVEN BUSINESS
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  We help our clients fulfil their business goals through our data analytics goals and help them step into the digital era by assisting them in harnessing the full potential of their data. Whether it's driving revenues, meeting compliance requirements, increasing operational efficiency, or creating an entirely new business model, Corner Tree can help you gain the maximum benefit with data analytics.
                  <br />   We are commonly known in the industry for supporting organisations' growth and strengthening their competitive advantage.
                  <br /><br />
                  By combining in-depth financial and functional knowledge with advanced and sophisticated technologies and techniques, we offer insights that support vital decision making.
                  Corner Tree provides consulting on data analytics, mining, governance, and visualisation.
                  <br /><br />
                  We use descriptive analytics, predictive models, and big data solutions to identify the on-going trends and define rules for our clients.



                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function SoftwareDevelopment() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Software Development
        </Typography>
      </Box>

      <Container maxWidth="xl" className="container"  >


        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" sx={{ my: 2 }}  >
                    C

                    BESPOKE SOFTWARE
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Our custom software and applications are designed to help your business push beyond the boundaries and do more. Our top-notch development team build solutions that turn your business process more efficient, enhance customer experience and generate more ROI
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    EXPERT DEVELOPERS                   </Typography>
                  <Typography variant="p" component="p"   >


                    Our developers are always up for new challenges and never say no to a project. In fact, our â€˜can doâ€™ attitude helps you expand your operations and scale up your project rapidly. Our entire team of developers deliver top results always.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    SOFTWARE MAINTENANCE
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Is your software or application not doing what it's supposed to? Don't fret; we can rescue your failing project, or maintain your current software to improve performance and extend its functionality.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  WHY CORNER TREE?
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Whether you have a B2B or a B2C project, we focus on understanding your business, your products/services, and your end-users needs to develop a piece of software or application that your users will appreciate.

                  Our agile project management tools help our developers build software faster, and adjust it quickly to the changing project requirements.


                  The application and software we develop add value to your business, and you can be sure you will enjoy all these benefits from day one of working with us.


                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function EnterpriseApplication() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Enterprise Application
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    KICK-OFF
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Analyzing your requirements
                    Building the team
                    Preparing technical documents
                    Creating a basic design
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    DISCOVERY                   </Typography>
                  <Typography variant="p" component="p"   >


                    Using Scrum methodology
                    Showing you the code every two weeks
                    Performing User Acceptance Testing
                    Deploying your app
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    SUPPORT
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Performing continuous server monitoring
                    Allocating team members to fix bugs
                    Providing general client support
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  OUR WORLD-CLASS
                  SOFTWARE SOLUTIONS
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  MAKING CUSTOM-BUILT SOFTWARE GREAT AGAIN
                  Are you in the hunt for a solid software foundation for your business? Wave goodbye to uncoordinated software stress out of your business and embrace enhanced productivity leveraging our expertise in software development.
                  DEDICATED DEVELOPMENT TEAMS THAT DELIVER
                  If you are tired of stagnant and standard software solutions, it is high time you opt for quality custom software solutions that meet your unique business requirements.
                  Whether you an established business or a startup looking to achieve business goals, you can work with us to develop top-notch enterprise applications for your business.
                  Our skilled and experienced team offers:

                  Enterprise application consultation
                  Custom software solutions
                  Enterprise application solutions
                  Offshore software development services
                  Software integration services
                  Backend support and maintenance
                  Join hands with us to transform raw ideas into a fully-developed and robust software or application for your enterprise!




                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function MobileWebDevelopment() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Mobile Web Development
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    COST EFFECTIVE
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Sometimes, the best solutions don't have to be expensive. As a customer-friendly development company, we always use the most viable technology for compatibility and cost.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    TRANSPARENCY                   </Typography>
                  <Typography variant="p" component="p"   >


                    To offer well-rounded services, we stay completely transparent and focus on clarity and no surprises.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    FLEXIBILITY
                  </Typography>
                  <Typography variant="p" component="p"   >
                    We love to work the way how our clients like it the best. From coding to approach and methodologies to tools, we always put our clientsâ€™ say first.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  USER-CENTRED
                  MOBILE AND WEB DEVELOPMENT
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  We exist to simplifying your customers' life and establishing a more connected way of work by leveraging meaningful technology.
                  We work with you to solve your organisational challenges, improve sales, and engage your audiences. Before starting with a project, we always take our time to understand your needs, get to the core of your problem, and create a strategy that gets results.

                  We don't do quick-fixes or off-the-shelf-technology. Instead, we develop excellent solutions that work across all devices and platforms seamlessly



                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function UIUX() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          UIUX
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    UX DESIGN
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Turning your product idea into a working prototype, crafting user flows, low-high wireframes, performing usability testing, and more.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    UIDESIGN                   </Typography>
                  <Typography variant="p" component="p"   >


                    Top-notch UI design, visually-focused on attracting and engaging the audience. Smooth interfaces, creative landing pages, animations, micro-interactions, and more
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    PRODUCT DESIGN
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Finding the right shapes, angles, and graphics to create a valuable design to help your product stand out.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Tell Your Brand Stories
                  DRIVE CONVERSION THROUGH UX/UI DESIGN SOLUTIONS
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  User interactions are valuable to your business. It drives conversions, and conversions drive revenue. Hence, you need to use the latest technology, unique design, and usability in the right proportion to create experiences that improve app stickiness and user retention

                  At Corner Tree, we move beyond just visual aspects of your product and focus on how people engage with the design to reshape their experience.

                  We help you tell your stories through visually stunning and engaging UI designs that prompt a conversion-driven response.

                  We like to keep our design sleek, bare minimum, and topping it up with essential brand elements.
                  To accomplish this, we follow a user-centric approach -understanding the design decisions by looking at how users interact with your app or website. The interface is kept self-explanatory to overcome the technical sophistication and achieve ease of use





                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function EmbeddedSolutions() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          EmbeddedSolutions
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    EMBEDDED PRODUCT LIFECYCLE
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Robust embedded product lifecycle solutions made to fit your concept, design, manufacturing, and maintenance capabilities.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    DESIGN CONSULTANCY                   </Typography>
                  <Typography variant="p" component="p"   >


                    The best design consultancy service in town that's both cost-effective and scalable.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    EMBEDDED SOFTWARE SOLUTIONS
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Embedded software solutions built to meet your businessâ€™s functional, performance, and safety goals
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Not Just Services/Products
                  SOLUTIONS TAILORED FOR YOUR BUSINESS
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  At Corner Tree, we are experts in offering embedded design development services to businesses hailing from all industry verticals. Our state-of-the-art, result-driven embedded solutions turn your software design or model architecture into a fully functional system based on your requirements.

                  Based in Bangalore, we provide end-to-end and tailor-made services that help our clients boost their business.

                  Right from our advent, we have worked on building Corner Treeâ€™s reputation as the best, effective, and unique embedded solution provider in the industry.

                  Our team is well versed in low- and high-level programming languages ranging from C, C++ to Java, and PHP.
                  This knowledge makes us proficient enough to work on embedded technologies and deliver the final product that meets all coding standards.







                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function QualityAssurance() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Quality Assurance
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    DATA CHECKING
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Data is edited, cleaned, verified, cross-checked, and validated through a combination of manual and automated procedures
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    NON-FUNCTIONAL                 </Typography>
                  <Typography variant="p" component="p"   >


                    Verifying the various attributes of the systems like memory leaks, performance, and stability of the system..
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    SECURITY TESTING
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Identifying threats in the application and finding its potential vulnerabilities to ensure the application doesnâ€™t stop functioning.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  Powerful Software Tools
                  QUALITY ASSURANCE SERVICES FOR OPTIMUM PERFORMANCE
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Corner Tree provides a broad range of quality assurance and testing services in India. We serve companies of all shapes and sizes -from bootstrap startups to midsize businesses and large enterprises. We work together with our clients right from the development phase to security testing.

                  Our primary focus is to offer quality assurance testing services that improve quality, time to market, reduce costs, and empower efficient IT processes.

                  We donâ€™t just provide you with powerful software tools but a dedicated team of experts who will work closely with you to improve your applicationâ€™s quality.

                  From training to conducting a gap analysis to performing quality surveys, and ensuring a quality management system, we help you move forward and take your business to heights.







                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function Support() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Support
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    Demand Management
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Maintaining and fulfilling high-level demands while providing cost savings and continuous service improvements.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    Storage & Distribution                </Typography>
                  <Typography variant="p" component="p"   >


                    Offering intelligent storage with enhanced support capabilities and distribution network solutions.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    Technical Services
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Design services, data analytics, information services, training solutions, platform integration, and more.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >

                  We Are Just A Click Away
                  Top-Notch Support Services
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  At Corner Tree, we are specialists in global and local support services and resources. With over seven years of combined experience, we feature a solid track record for expert service delivery. Our simple single point of contact solution with 24x7 access to numerous IT engineers make us one of the best support services companies in the country. Further, our multivendor approach makes sure that we can immediately match the right skill set to your requirements.






                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

 

function ApplicationSupport() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          Application Support
        </Typography>
      </Box>
      <Container maxWidth="lg"  >


        <Grid maxWidth="lg" container   >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    C

                    Troubleshooting
                  </Typography>
                  <Typography variant="p" component="p"   >


                    We scan your system thoroughly to get to the bottom of any issues and fix them at the earliest.
                  </Typography>
                  <Button sx={{ mt: 2,  px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    Contingency Plans               </Typography>
                  <Typography variant="p" component="p"   >


                    Coming up with application maintenance and support strategies to avoid accidents and unforeseen mishaps.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    Fixing Errors/ Bugs
                  </Typography>
                  <Typography variant="p" component="p"   >
                    A dedicated application support services team that fixes bugs and errors in your system ensures a smooth experience.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >

                  Support Throughout Your Project Lifecycle
                  Quick and Easy Solutions For All Your App-Related Worries
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  At Corner Tree, we value our customers and make sure we fulfil all their software-related needs.

                  From mobile app to web-based applications, we ensure that we walk with our customers to every end of the project journey. Soon after the development phase is complete, and the product is finalised, we help you launch it and further assist you in maintaining the application.

                  Our expert team always stays at your assistance to offer support and advice.

                  Your Problems, Our Solutions
                  All The Application Support You Need
                  We offer varying levels of support plans customised to our clients' requirements. Our application maintenance always stays on standby, ready to address any issues you might face.

                  Our team members work on your application with you around the clock to resolve the problems and ensure that you are satisfied with the services.






                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

function ITSupports() {
  return (
    <div className={styles.container}  >

      <Header /> <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          IT Support  
        </Typography>
      </Box>
      <Container maxWidth="lg"  >



        <Grid maxWidth="lg" container style={Gridshome} >
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                    
                    IT SECURITY
                  </Typography>
                  <Typography variant="p" component="p"   >


                    Protecting your IT systems, business data, and IP with our unique security solutions.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}  	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />

                  <Typography variant="h5" component="h5" sx={{ my: 2 }}  >


                    IT SERVICES               </Typography>
                  <Typography variant="p" component="p"   >


                    Experience the benefits of working with an IT consultancy that offers mailbox and data migration, hosted solutions, and more.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
          <Grid item xs={12} md={4}   	>
            <Item sx={{ mx: 4, my: 2 }} >
              <Box >
                <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
                  <img src={hero} width={150} sx={{ mx: 'auto' }} alt="Corner Tree" />
                  <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                    
                    MONTHLY IT SUPPORT
                  </Typography>
                  <Typography variant="p" component="p"   >
                    Monthly IT support plans free up your time, save money, and help you focus on your core business.
                  </Typography>
                  <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                </Paper>
              </Box>

            </Item>
          </Grid>
        </Grid>


        <Grid maxWidth="lg" container style={Gridshome}  >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>
                
                <Typography variant="h4" component="h4" sx={{}}  >

                  Focus On Your Business  <Divider />
                  IT SUPPORT WITH THE PERSONAL TOUCH
                </Typography>

                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >

                  Right from our advent, we have been delivering real difference for businesses of all sizes across the country by providing cutting-edge technology and affordability they demand.

                  Information is the lifeblood of enterprise today.<br /><br /> You can trust our specialists when it comes to tailored, flexible, and end-to-end IT support services and security solutions. Rest assured, our personalised services and customer care will help keep your technology up and running so you can focus on running your business.




                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}
