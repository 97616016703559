import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export  const Item = styled(Box)(() => ({

    textAlign: 'left',
}));

 
export const Gridshome = {

    marginTop: '8rem',
    marginBottom: '5rem',
    marginRight: 'auto',
    marginLeft: 'auto'
}
 