import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
  
import styles from './style.module.css';

import { Gridshome, Item } from './main';

export default function SimpleContainer() {
    return (<>
        <Box className={styles.footer}>
            <Container sx={{ py: 1 }}  maxWidth="lg"   >

                <Grid maxWidth="lg" container style={Gridshome} >
                    <Grid item xs={12} md={4} mdOffset={1}  	>


                        <Item sx={{ p: 2 }} >
                            <Typography variant="h5" component="h5">
                                Corner Tree Consulting
                            </Typography>
                            <br />
                            <Typography variant='p' component="p" >
                                <strong> US & Canada:</strong>   Canada is 99 Prince Street, Komoka, N0L 1R0, Ontario, Canada
                            </Typography>
                            <br />
                            <Typography variant='p' component="p">
                                <strong> Europe:</strong>   515 Abbeville Apartment- 37 London Road. Essex Barking -IG118FW
                            </Typography>
                            <br />
                            <Typography variant='p' component="p">
                                <strong> UAE:</strong>  502, NBQ Building, Bank Street, Bur Dubai, Dubai -UAE.
                            </Typography>
                            <br />
                            <Typography variant='p' component="p" >
                                <strong> India:</strong>   2nd floor, Bridge+, Park Square Mall, ITPL, Whitefield, Bangalore (India) -560066
                            </Typography>
                            <br />
                        </Item>
                    </Grid>
                    <Grid xs={12} md={1} sx={{ p: 2 }}   >
                    </Grid>
                    <Grid xs={12} md={3} sx={{ p: 2 }}   >
                        <Item>
                            <Typography variant="h5" component="h5">
                                General
                            </Typography>
                            <List>
                                <ListItem>

                                    <Link href="/">
                                        <ArrowForwardIosSharpIcon sx={{ fontSize: '12px' }} />
                                        Home
                                    </Link>
                                </ListItem>



                                <ListItem>
                                    <Link href="/about">
                                        <ArrowForwardIosSharpIcon sx={{ fontSize: '12px' }} />
                                        About
                                    </Link>
                                </ListItem>

                                <ListItem>
                                    <Link href="/contact">
                                        <ArrowForwardIosSharpIcon sx={{ fontSize: 12 }} />
                                        Contact
                                    </Link>
                                </ListItem>
                            </List>
                        </Item>

                    </Grid>

                    <Grid item xs={12} md={3} sx={{ p: 2 }}    >

                        <Typography variant="h5" component="h5">
                            Solutions
                        </Typography>
                        <List>
                            <ListItem  >
                                <Link href="/people">
                                    <ArrowForwardIosSharpIcon sx={{ fontSize: '12px' }} />People
                                </Link>
                            </ListItem>
                            <ListItem  >
                                <Link href="/technology">
                                    <ArrowForwardIosSharpIcon sx={{ fontSize: 12 }} />
                                    Technology
                                </Link>
                            </ListItem>
                            <ListItem  >
                                <Link href="/digital-innovation">

                                    <ArrowForwardIosSharpIcon sx={{ fontSize: 12 }} />
                                    Digital Innovation
                                </Link>

                            </ListItem>
                        </List>

                    </Grid>
                    <Divider />
                    <Grid item xs={12} lg={8} sx={{ p: 2 }}  >
                        <Typography variant="p" component="p">
                            2019 © All rights reserved by Corner Tree Consulting P Ltd
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={4} sx={{ p: 2 }}  >
                        <Typography variant="p" component="p">
                            Follow us
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
 
    </>
    );
}
