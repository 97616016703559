import Footer from './footer';
import Header from './header';
import Homepage from './home-page';
 
export default function SimpleContainer() {
  return (
    
      <div>
         <Header/>
         <Homepage/>
         <Footer />
      </div>

    );
}
