 import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Footer from './footer';
import Header from './header';
  
 import { Typography } from '@mui/material';
import { Item } from './main';
import Grid from '@mui/material/Unstable_Grid2';
 import hero from './images/hero.svg';
 import styles from './style.module.css';




function About() {
  return (
    <div className={styles.container}  >

      <Header />

      <Box className={styles.breadcrumb} >
        <Typography variant="h3" >
          About
        </Typography>
      </Box>
      <Container maxWidth="lg" className={styles.container}   >



        <Grid maxWidth="xs" container   >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  WE HELP YOU BUILD YOUR FUTURE
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Corner Tree Consulting was incorporated in the year 2018 by thinkers and innovators who were determined to reimagine the way IT outsourcing decisions are made.We are passionate about helping businesses leverage IT as a strategic asset.
                  Empowered by technology expertise, unwavering focus, and flawless execution capabilities, we bring quality over quantity and deliver remarkable experiences.
                  <br />We are driven by our purpose to create prominent outcomes for our customers, partners, employees, investors, and society. Thus, we are devoted to being a responsible business -in the way we operate, respect our people, serve the community, and deliver returns to our investors.

                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Our extensive client base demonstrates the complex and unique challenges we have faced and overcome, which made us quite the experts at what we do. Every day we help numerous people with innovative solutions that transform and simplify their business operations and help them seamlessly connect with customers.
                  Our cutting-edge technologies give time back to our clients, allowing them to focus on the things they do the best.
                  <br /><br />
                  We operate in the Europe, UAE, and India -our diverse services are categorised into People Solutions, Technology Solutions, Automation and Analytics, Software and Application Development, Service Delivery and Support, and Continuous Innovation.
                </Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>
        <Grid maxWidth="lg" container   >
          <Grid item xs={12} md={8}    >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                <Typography variant="h4" component="h4" sx={{}}  >
                  WHAT WE BELIEVE
                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Our belief is a key recipe to business success, small or big, is a mix of Skillful & adaptive talent combined with scalable technology solutions.
                  We at Corner Tree Consulting believe that ultimate sophistication lies in simplicity. Hence, we always strive to build scalable technology solutions that fulfil the requirements of our customers in the simplest of ways.


                </Typography>
                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                  Right since our advent, we have been a steadfast company with a reliable and result-oriented global delivery network focused on your success.
                  Our multi-cultural team, consisting of creative strategists, analysts, and masterminds across our Europe, India, and UAE offices work round-the-clock.
                  <br /><br />
                  We take pride in delivering innovative digital experiences to help our clients make an impact because we are confident that memorable experiences are the best way to connect with your customers.
                  From dynamic software to cutting-edge RPO solutions & talent, we believe our custom solutions are what will transcend the trend of tomorrow.</Typography>

              </Box>

            </Item>

          </Grid>


          <Grid item xs={12} md={4}   >
            <Item>
              <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                <img src={hero} alt="df" width={280} />

              </Box>

            </Item>

          </Grid>

        </Grid>

      </Container>
      <Footer />

    </div>
  );
}

export default About;