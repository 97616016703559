
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import hero from './images/hero.svg';
import helppic from './images/icon-illustration/instagram_post__monochromatic.png';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { Gridshome,  Item } from './main';
import Container from '@mui/material/Container';
import styles from './style.module.css';




export default function Homepage() {
    return (
        <div>

            <Container maxWidth="lg" className={styles.container}  >
                

                <Grid maxWidth="lg" style={Gridshome}  container   >
                <Grid item xs={12} md={7} sx={{ px: 'auto' }} >
                    <Item sx={{   p: 5 }} >

                        <Typography variant="h3" component="h3"   >
                            Explore our full-scale staffing solutions to optimize
                        </Typography>
                        <Typography variant="p" component="p"  sx={{mt:2}} >
                            Our experienced solution team, along with leading partners, deliver solutions that help you radically optimise their business processes.
                        </Typography>
                        <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>


                    </Item>
                </Grid>
                <Grid item xs={12} md={5} >
                    <Item>
                        <Box sx={{ mx: 'auto' }}>
                            <center>
                                <img src={hero} width={350} sx={{ mx: 'auto' }} alt="Corner Tree" />
                            </center>

                        </Box>

                    </Item>
                </Grid>


            </Grid>


                <Grid maxWidth="lg" container style={Gridshome}  >
                <Grid item xs={12} md={4}   	>
                    <Item sx={{ mx: 4, my: 2 }} >
                        <Box >
                            <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >
                                <img src={hero} width={250} sx={{ mx: 'auto' }} alt="Corner Tree" />
                                <Typography variant="h5" component="h5" sx={{my:2}}  >
                                    People  & Talent                           </Typography>
                                <Typography variant="p" component="p"   >


                                    We align leaders and work to provide top-quality talent and help define our client's success outcomes.

                                </Typography>
                                <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
                            </Paper>
                        </Box>

                    </Item>
                </Grid>
                <Grid item xs={12} md={4}  	>
                    <Item sx={{ mx: 4, my: 2 }} >
                        <Box >
                            <Paper elevation={5} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#ccc 1px solid' }} >

                            <img src={hero} width={250} sx={{ mx: 'auto' }} alt="Corner Tree" />

                            <Typography variant="h5" component="h5" sx={{ my: 2 }}  >
                                Technology                          </Typography>
                            <Typography variant="p" component="p"   >


                                We align leaders and work to provide top-quality talent and help define our client's success outcomes.

                            </Typography>
                            <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
</Paper>
                        </Box>

                    </Item>
                </Grid>
                <Grid item xs={12} md={4}   	>
                    <Item sx={{ mx: 4, my: 2 }} >
                        <Box >
                            <Paper elevation={2} sx={{ textAlign: 'center', background: '#fff', p: 3, border: '#cccc 1px solid' }} >
   <img src={hero} width={250} sx={{ mx: 'auto' }} alt="Corner Tree" />
                            <Typography variant="h5" component="h5" sx={{ my: 2 }}   >
                                Innovation                           
                            </Typography>
                            <Typography variant="p" component="p"   >
                                We align leaders and work to provide top-quality talent and help define our client's success outcomes.
                            </Typography>
                            <Button sx={{ mt: 2, textTransform: 'capitalize', bgcolor: 'secondary.main', color: '#fff', borderRadius: '20px', px: 2 }} >Learn more</Button>
</Paper>
                        </Box>

                    </Item>
                </Grid>
            </Grid>

                <Grid maxWidth="lg" container style={Gridshome} >
                    <Grid item xs={12} md={8} mdOffset={2}  	>
                        <Item>
                            <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                                <Typography variant="h3" component="h3"   >
                                    About us                            </Typography>
                                <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                                    We are a forward-looking group of passionate professional with relevant experience to match. Our experienced solution team, along with leading partners, deliver solutions that help you radically optimise their business processes.
                                </Typography>

                            </Box>

                        </Item>
                    </Grid>
                     


            </Grid>



                <Grid maxWidth="lg" style={Gridshome} container  sx={{ background:'#090709',color:'#fff', marginLeft:'auto', marginRight:'auto',  p:4}} >
                <Grid item xs={12} md={3}   	>
                    <Item sx={{ mx: 'auto' }} >
                        <Box sx={{ textAlign: 'center' }}>

                            <Typography variant="h3" component="h3" sx={{}}  >
                                2100+                           </Typography>
                            <Typography variant="p" component="p"   >
                                Projects Delivered

                            </Typography>

                        </Box>

                    </Item>
                </Grid>
                <Grid item xs={12} md={3}  	>
                    <Item sx={{ mx: 'auto' }} >
                        <Box sx={{ textAlign: 'center' }}>

                            <Typography variant="h3" component="h3" sx={{}}  >
                                100+                           </Typography>
                            <Typography variant="p" component="p"    >
                                Happy Clients

                            </Typography>

                        </Box>

                    </Item>
                </Grid>
                <Grid item xs={12} md={3}   	>
                    <Item sx={{ mx: 'auto' }} >
                        <Box sx={{ textAlign: 'center' }}>

                            <Typography variant="h3" component="h3" sx={{}}  >
                                40+                           </Typography>
                            <Typography variant="p" component="p"   >
                                Industries We Serve

                            </Typography>

                        </Box>

                    </Item>
                </Grid>
                <Grid item xs={12} md={3}   	>
                    <Item sx={{ mx: 'auto' }} >
                        <Box sx={{ textAlign: 'center' }}>

                            <Typography variant="h3" component="h3" sx={{}}  >
                                50+
                    </Typography>
                            <Typography variant="p" component="p"   >
                                Technology

                            </Typography>

                        </Box>

                    </Item>
                </Grid>

            </Grid>


                <Grid maxWidth="lg" container style={Gridshome}   >
                <Grid item xs={12} md={8}    >
                    <Item>
                        <Box sx={{ mx: 'auto', textAlign: 'left' ,p:4 }}>

                            <Typography variant="h4" component="h4" sx={{}}  >
                                    EXPERTISE BASED ON EXPERIENCE

                           </Typography>
                            <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                                    Over the years, we have developed several fields of expertise. We are confident that by incorporating state-of-the-art technologies into your business, you can cater to the ever-changing market dynamics.

                                    Thus, through strengthened links and advanced business and technology intelligence, we help you build your future.
                            </Typography>

                        </Box>

                    </Item>
                    
                </Grid>


                <Grid item xs={12} md={4}   >
                    <Item>
                        <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                            <img src={helppic} alt="df"  width={280} />

                        </Box>

                    </Item>

                </Grid>

            </Grid>

                <Grid maxWidth="lg" container style={Gridshome}   >
               
                <Grid item xs={12} md={4}   >
                    <Item>
                        <Box sx={{ mx: 'auto', textAlign: 'center' }}>

                            <img src={helppic} alt="df" width={280} />

                        </Box>

                    </Item>

                </Grid>
                <Grid item xs={12} md={8}    >
                    <Item>
                        <Box sx={{ mx: 'auto', textAlign: 'left', p: 4 }}>

                            <Typography variant="h4" component="h4" sx={{}}  >
                                WE HELP YOU BUILD YOUR FUTURE
                            </Typography>
                            <Typography variant="p" component="p" sx={{ mt: '1rem' }}  >
                                We are a forward-looking group of passionate professional with relevant experience to match. Our experienced solution team, along with leading partners, deliver solutions that help you radically optimise their business processes. Headquartered in Bangalore, we cater to customer across the country
                            </Typography>

                        </Box>

                    </Item>

                </Grid>

            </Grid>


            </Container>


        

        </div>
    );
}
